export const getters = {
  computedFrontPageOptions: () => (firstPageSelections) => {
    const gtnOptions = [
      {
        id: "blank_template",
        value: "Blank template"
      },
      {
        id: "single_security_template",
        value: "Single security template"
      },
      {
        id: "multiple_security_template",
        value: "Multiple security template"
      }
    ];
    return gtnOptions.map((obj) => ({
      id: obj.id,
      value: obj.value,
      isSelected: firstPageSelections
        ? firstPageSelections.includes(obj.id)
        : false,
      disabled: true,
      canMove: false
    }));
  }
};
